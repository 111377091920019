import './App.css';

function App() {
  return (
    <div className="p-4">

      <p className='text-green-900 text-8xl text-center'>DANCING PEOPLE IN POWER</p>
      <p className='text-green-900 text-center'>***THIS IS ART, DO NOT SUE NOTPINK***</p>
      <p className='text-green-900 text-center'>email for enquires: junk@notpink.xyz</p>

      <br></br>
      <p className='text-gray-200 m-auto bg-purple-800 w-fit rounded-md px-2 underline font-medium'><a href="https://discord.gg/khjDqqu5Tj" target="_blank">join notpink.xyz discord 🌷</a></p>
      <p className='text-gray-200 text-center mt-3 m-auto bg-green-800 w-fit rounded-md px-2 underline font-light mb-2'><a href="https://twitter.com/notpinkxyz/status/1653864224609673261" target="_blank">please like & share our post on twitter</a></p>

      <p className='text-green-900 text-center'>please do all of the above, we promse to deliver more interesting projects.</p>
      <p className='text-green-900 text-center m-auto '>lastly, do you want to suggest edits? fill this <a href="https://tally.so/r/w81oqA" target="_blank" className='underline'>form</a>.</p>
      <br></br>
      <div className="App flex flex-wrap justify-center text-green-900">
        <div className='video-container'>
          <video src="9.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>ELON MUSK</p>
        </div>
        <div className='video-container'>
          <video src="2.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>CRISTIANO RONALDO</p>
        </div>
        <div className='video-container'>
          <video src="3.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>MARK ZUCKERBERG</p>
        </div>
        <div className='video-container'>
          <video src="4.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>WARREN BUFFETT</p>
        </div>
        <div className='video-container'>
          <video src="5.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>JOE BIDEN</p>
        </div>
        <div className='video-container'>
          <video src="6.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>DONALD TRUMP</p>
        </div>
        <div className='video-container'>
          <video src="7.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>KANYE WEST</p>
        </div>
        <div className='video-container'>
          <video src="1.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>DALAI LAMA</p>
        </div>
        <div className='video-container'>
          <video src="8.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>POPE FRANCIS</p>
        </div>
        <div className='video-container'>
          <video src="10.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>JEFF BEZOS</p>
        </div>
        <div className='video-container'>
          <video src="11.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>SAM ALTMAN</p>
        </div>
        <div className='video-container'>
          <video src="12.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>LEBRON JAMES</p>
        </div>
        <div className='video-container'>
          <video src="13.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>KIM KARDASHIAN</p>
        </div>
        <div className='video-container'>
          <video src="14.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>PETE DAVIDSON</p>
        </div>
        <div className='video-container'>
          <video src="15.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>ROBERT DOWNEY JR.</p>
        </div>
        <div className='video-container'>
          <video src="16.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>MR. BEAST</p>
        </div>
        <div className='video-container'>
          <video src="17.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>SERENA WILLIAMS</p>
        </div>
        <div className='video-container'>
          <video src="18.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>MALALA YOUSAFZAI</p>
        </div>
        <div className='video-container'>
          <video src="19.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>KEVIN HART</p>
        </div>
        <div className='video-container'>
          <video src="20.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>DWAYNE JOHNSON</p>
        </div>
        <div className='video-container'>
          <video src="21.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>LOGAN PAUL</p>
        </div>
        <div className='video-container'>
          <video src="22.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>BILL GATES</p>
        </div>
        <div className='video-container'>
          <video src="23.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>SUSAN WOJCICKI</p>
        </div>
        <div className='video-container'>
          <video src="24.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>ANDREW TATE</p>
        </div>
        <div className='video-container'>
          <video src="25.mp4" autoPlay={true} loop={true} muted={true}></video>
          <p className='text-center px-3'>OPRAH WINFREY</p>
        </div>
      </div>

      <br></br>


      <p className='text-green-900 text-center'>*** BUILT BY NOTPINK.XYZ ***</p>
      <br></br>
      <p className='text-gray-200 m-auto bg-purple-800 w-fit rounded-md px-2 underline font-medium'><a href="https://discord.gg/khjDqqu5Tj" target="_blank">join notpink.xyz discord 🌷</a></p>
      <p className='text-gray-200 text-center mt-3 m-auto bg-green-800 w-fit rounded-md px-2 underline font-light mb-2'><a href="https://twitter.com/notpinkxyz/status/1653864224609673261" target="_blank">please like & share our post on twitter</a></p>
      <br></br>
    </div>
  );
}

export default App;
